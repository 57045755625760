import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

export default () => (
  <DefaultLayout
    pageTitle="Privacy Policy"
    pageDescription="&yet’s default privacy policy."
    bodyClass="privacy"
  >
    <div className="container">
      <section>
        <div className="content-narrower">
          <h1>Privacy Policy</h1>
          <p>Our default privacy policy is never to gather, store or sell information about you, or to engage in any other behavior that would compromise your privacy and security in any way. By default we do not gather personally identifying information.</p>

          <h3>Anonymous Metrics</h3>
          <p>We collect anonymous website usage data (with no personally identifying information attached) to improve our sites. For example, we measure what pages people are visiting and how long visitors stay on our site. We use Google Analytics for this purpose.</p>

          <h3>Contact Form</h3>
          <p>Do not submit sensitive information, such as payment information or passwords, via our contact form. Information sent via our contact form may be shared amongst our team. We will not use your contact information for any purpose, including marketing emails, except to contact you regarding your inquiry.</p>

          <p>If you have questions or concerns, you can reach us at <a href="mailto:support@andyet.com">support@andyet.com</a>.</p>
        </div>
      </section>
    </div>
  </DefaultLayout>
);
